import api from "api";
import Default_Profile from "assets/img/default_profile.png";
import { Storage } from "aws-amplify";
import ImageInput from "components/Input/ImageInput";
import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import InsuranceDetailsModal from "components/Modal/InsuranceDetailsModals/InsuranceDetailsModal";
import DocumentForm from "components/PatientDocument/DocumentForm";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import {
  BILLING_TYPES_OPT,
  CONFIG,
  CUSTOM_INS,
  EMP_INS_OBJECT,
  IMAGE_UPLOAD_TYPE,
  INSURANCE_EMPTY_OBJECT,
  MEDICARE_ID,
  NEW_PROVIDER_OBJECT,
  PROFILE_IMAGE_PATH,
  countryListAllIsoData,
  usersNo,
} from "constant";
import countriesOption from "countries";
import moment from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import insServices from "services/InsuranceService";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { documentSelector } from "store/features/documents/documentSelectors";
import { deleteDcoumentAsync, fetchDocuemntAsync } from "store/features/documents/documentSlice";
import {
  setOpenInsuranceModal as setOpenInsModal,
  setSelectedInsurance,
} from "store/features/insurances/insuranceSlice";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import {
  newUserProviderSelector,
  openCreatorProviderSelector,
  selectedProvider,
} from "store/features/providers/providersSelectors";
import { setOpenCreatorProviderModal } from "store/features/providers/providersSlice";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { t } from "../../stringConstants";
import {
  convertToLower,
  formatCurrency,
  formatProvidersLabel,
  getEmployeeIndexValue,
  getStateLength,
  getValidDep,
  getValidSpaces,
  isValidEmail,
  isValidIDNumber,
  isValidName,
  isValidZipCode,
  parseInsDetails,
  searchPhone,
  toTitleCase,
  userCompanyID,
  validatePhone,
} from "../../utils";
import DocumentList from "../PatientDocument/DocumentList";
import RadioButton from "../RadioButton/RadioButton";
import InsuranceViewCard from "./InsuranceViewCard";
import ProfileRelationModal from "./employeeRelationModalOnModal";
import { AppContext } from "../../context/app-context";
import RelationView from "./RelationView";
import EditIcon from "../../assets/img/edit-icon.png";
import { mergeArraysById } from "utils";
import { authSelector } from "store/features/authentication/authenticationSelectors";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
};

const EmployeeModal = (props) => {
  const { user, handleSave, handleClose, resetCall, importMember, cssClass } = props;
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState(user);
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  // set default true as per requirements
  // newUser.status === "active" ? false : true
  const [editButton, setEditButton] = useState(false);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [regions, setRegions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [selectedVal, setSelectedVal] = useState(user.isNew ? user.department : "");
  const [autoShipmentConfirmation, setAutoShipmentConfirmation] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [insuranceCardFront, setInsuranceCardFront] = useState("");
  const [insuranceCardBack, setInsuranceCardBack] = useState("");
  const [openInsuranceModal, setOpenInsuranceModal] = useState(null);
  const [providersOptions, setProvidersOptions] = useState([]);
  const firstNameRef = useRef();
  const setting = useSelector(selectedCompanySetting);
  const { providers } = useSelector(selectedProvider);
  const { locations, userLocation } = useSelector(selectedLocation);
  const { subAgents, userSubAgent } = useSelector(selectedSubAgent);
  const loginUser = useSelector(selectLoginUser);
  const openCreatorProivderModal = useSelector(openCreatorProviderSelector);
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });
  const [relations, setRelations] = useState([]);
  const [Employee, setEmployee] = useState();
  const [connected, setConnected] = useState([]);

  const { permission } = useSelector(authSelector);

  const getConnectedData = async () => {
    if (!connected || connected.length === 0) {
      return;
    }
    const data = await api.getConnectedProfiles({ ids: connected?.map((c) => c.id) });
    const connectedEmployees = data?.data || [];
    const formattedRelationalData = mergeArraysById(connected, connectedEmployees);
    setRelations(formattedRelationalData);
  };

  useEffect(() => {
    getConnectedData();
  }, [connected, show]);

  const { documents } = useSelector(documentSelector);
  const dispatch = useDispatch();
  const [showProfileModal, setProfileModal] = useState(false);

  const appContext = useContext(AppContext);

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(`VaccinationImage/${file}`);
        setImage(path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  useEffect(async () => {
    // parse Insurance Details
    const insuranceDetails = parseInsDetails(user.insuranceDetails, user);
    setNewUser({
      ...user,
      ...(!user.primaryInsurance && { primaryInsurance: { copay: 0, deductible: 0 } }),
      ...(user.medicareNo && !MEDICARE_ID.includes(user.insuranceCompany) && { isMedicare: "Y" }),
      ...(!user.medicareNo && { isMedicare: "N" }),
      insuranceDetails,
      isoCode: countryListAllIsoData.find((f) => f.name === user.country)?.code || "US",
    });
    if (!user.isNew && user.picture) {
      setProfileImage(`${PROFILE_IMAGE_PATH}${user.picture}`);
    }
    if (!user.isNew && user.insuranceCardFront) {
      await getFileFromStorage(user.insuranceCardFront, setInsuranceCardFront);
    }
    if (!user.isNew && user.insuranceCardBack) {
      await getFileFromStorage(user.insuranceCardBack, setInsuranceCardBack);
    }
    if (!user.isNew) {
      dispatch(fetchDocuemntAsync({ employeeID: user.id, noShowLoader: true }));
    }
  }, [user]);

  useEffect(async () => {
    if (providers?.length > 0 && setting) {
      const primaryCarePhysicianLabel = providers.find(
        (f) => f.id === setting?.defaultSetting?.primaryCarePhysician
      )?.name;
      const referringPhysicianLabel = providers.find((f) => f.id === setting?.defaultSetting?.referringPyhsician)?.name;

      if (newUser.isNew) {
        setNewUser({
          ...newUser,
          primaryCarePhysician: user.primaryCarePhysician || primaryCarePhysicianLabel,
          referringPyhsician: referringPhysicianLabel,
          renderingProvider: user.renderingProvider || setting?.defaultSetting?.renderingProvider,
          referringProvider: user.referringProvider || setting?.defaultSetting?.referringProvider,
          orderingProvider: user.orderingProvider || setting?.defaultSetting?.orderingProvider,
        });
      }
      let arr = [];
      for (let val of providers) {
        const formattedLabel = formatProvidersLabel(getValidDep(val.name), val?.code || "");
        arr.push({
          labelVal: getValidDep(val.name),
          label: formattedLabel,
          value: val.id,
        });
      }
      setProvidersOptions(arr);
    }
  }, [providers]);

  useEffect(() => {
    if (selectedVal) {
      const calltime = departments.find((dpt) => selectedVal === dpt.id)?.callTime || "";
      const timeZone = departments.find((dpt) => selectedVal === dpt.id)?.deptTimeZone || null;
      setTimeZone(timeZone);
      const obj = { ...newUser, callTime: calltime };
      setNewUser(obj);
    }
  }, [selectedVal, departments]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (timeZone === null || timeZone) {
      const obj = { ...newUser, empTZ: "" };
      setNewUser(obj);
    }
  }, [timeZone]);

  useEffect(() => {
    if (user) {
      setConnected(user.connectedTo);
    }
  }, [user, show]);

  const handleUpdatePatientAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const checkValidation = (newUser) => {
    if (loginUser.phone_number === usersNo.ibrahim) return true;
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setShow("First name is Required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setShow("Last name is Required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 1 letters and Name should be realistic");
      return;
    }

    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is Required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }

    if (!newUser.dob) {
      setShow("Date of Birth is Required");
      return;
    }

    if (!newUser.street || !newUser.street.trim()) {
      setShow("Address is Required");
      return;
    }

    if (!newUser.city || !newUser.city.trim()) {
      setShow("City is Required");
      return;
    }

    if (!newUser.state || !newUser.state.trim()) {
      setShow("State is Required");
      return;
    }
    if (newUser.state.length > getStateLength(newUser)) {
      setShow("State must be 2 characters");
      return;
    }
    if (!newUser.zip) {
      setShow("Zip Code is Required");
      return;
    }
    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      setShow("Zip Code is Invalid");
      return;
    }
    // if (!newUser.country) {
    //   setShow("Country is Required");
    //   return;
    // }

    if (!newUser.isSignUp && !importMember && newUser.isMemberLogin === "Y") {
      if (!newUser.password) {
        setShow("Password is Required");
        return;
      }
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        setShow(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    if (!newUser.gender) {
      setShow("Required gender");
      return;
    }

    if (!newUser.insuranceCompany) {
      setShow("Primary Payer Information is Required");
      return;
    }

    if (!CUSTOM_INS.includes(newUser.insuranceCompany)) {
      if (!newUser.medicalNo || !newUser.medicalNo.trim()) {
        setShow(`${newUser.insuranceGroupId ? "Member ID" : "Medicare Number"}  is Required`);
        return;
      }

      if (!isValidIDNumber(newUser.medicalNo)) {
        setShow(`${newUser.insuranceGroupId ? "Member ID" : "Medicare Number"}  is invalid`);
        return;
      }

      if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
        setShow("Member ID Not be Empty");
        return;
      }
      if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
        setShow("Member ID is invalid");
        return;
      }
    }
    if (CONFIG.isLabType) {
      // if (!newUser.primaryCarePhysician) {
      //   setShow("Primary CarePhysician is required");
      //   return;
      // }
      // if (!newUser.referringPyhsician) {
      //   setShow("Referring Pyhsician is required");
      //   return;
      // }
      // if (!newUser.renderingProvider) {
      //   setShow("Rendering Provider is Required");
      //   return;
      // }
    }
    return true;
  };

  const checkValidationForBioLab = (newUser) => {
    if (loginUser.phone_number === usersNo.ibrahim) return true;
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setShow("First name is Required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setShow("Last name is Required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 1 letters and Name should be realistic");
      return;
    }

    if (newUser.insuranceCompany === "00001") {
      if (newUser.email && !isValidEmail(newUser.email)) {
        setShow("Invalid Email Address");
        return;
      }

      if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
        setShow("Phone number is Required");
        return;
      }
      if (!validatePhone(newUser.phoneNumber)) {
        setShow("Invalid Phone Number");
        return;
      }
    }

    if (!newUser.dob) {
      setShow("Date of Birth is Required");
      return;
    }

    if (!newUser.gender) {
      setShow("Required gender");
      return;
    }

    if (!newUser.insuranceCompany) {
      setShow("Insurance Provider is Required");
      return;
    }

    if (!CUSTOM_INS.includes(newUser.insuranceCompany)) {
      if (!newUser.medicalNo || !newUser.medicalNo.trim()) {
        setShow(`Insurance Number is Required`);
        return;
      }

      if (!isValidIDNumber(newUser.medicalNo)) {
        setShow(`Insurance Number is invalid`);
        return;
      }

      if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
        setShow("Medicare Number Not be Empty");
        return;
      }
      if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
        setShow("Medicare Number is invalid");
        return;
      }
    }

    return true;
  };

  // fetch inusruance modal
  const fetchInsuranceWithPayerID = async (mergedObj) => {
    let ins = await insServices.fetchInsurances({ payerId: mergedObj.insuranceCompany })?.rows;
    // if e-Eligibility is False then Claim not Check Eligibility for this insurance
    if (ins && ins.length > 0 && !ins[0].eEligibility) {
      Object.assign(mergedObj, { eEligibility: true });
    }
  };

  const onHandleSave = async (isConfirm, newUser) => {
    if (!newUser.isNew && permission?.employee && permission.employee.editPatient === false) {
      appContext.showErrorMessage("This user has not permission to edit patient record.");
      return;
    }
    const primaryIns = newUser.insuranceDetails[0] || {};
    Object.assign(newUser, {
      insuranceCompany: primaryIns.insuranceCompany,
      insuranceCompanyCode: primaryIns.insuranceCompanyCode,
      insuranceType: primaryIns.insuranceType,
      copay: primaryIns.copay,
      deductible: primaryIns.deductible,
      insuranceGroupId: primaryIns.insuranceGroupId,
      isMedicare: primaryIns.isMedicare,
      medicalNo: primaryIns.medicalNo,
      medicareNo: primaryIns.medicareNo,
      employeeFND: getEmployeeIndexValue(newUser),
    });
    const validation = CONFIG.isBioLab ? checkValidationForBioLab(newUser) : checkValidation(newUser);
    if (!validation) return;

    setLoading(true);

    const isRecordExists = await api.isUserDemoExists({
      id: newUser.id,
      name: newUser.employeeFND,
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      dob: newUser.dob,
      gender: newUser.gender,
    });

    if (newUser.isNew && isRecordExists && isRecordExists.length > 0) {
      setShow("Patient Already Exists with same Name, Gender and Date of Birth");
      setLoading(false);
      return;
    }
    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }

    let insuranceCardFrontFileName = "";
    let insuranceCardBackFileName = "";
    if (insuranceCardFront instanceof Object) {
      insuranceCardFrontFileName = await api.saveFileStorage(
        newUser.id,
        IMAGE_UPLOAD_TYPE.frontCard,
        insuranceCardFront
      );
    }

    if (insuranceCardBack instanceof Object) {
      insuranceCardBackFileName = await api.saveFileStorage(newUser.id, IMAGE_UPLOAD_TYPE.backCard, insuranceCardBack);
    }

    if (insuranceCardFrontFileName) {
      Object.assign(newUser, {
        insuranceCardFront: insuranceCardFrontFileName,
      });
    }
    if (insuranceCardBackFileName) {
      Object.assign(newUser, { insuranceCardBack: insuranceCardBackFileName });
    }

    if (newUser.subAgent) {
      let tests = null;
      if (newUser.isNew) {
        tests = newUser.subAgent?.testLimit;
      } else {
        tests = newUser.subAgent?.testLimit - newUser.testOrdered;
      }

      Object.assign(newUser, { testAvailable: tests < 0 ? 0 : tests });
    }

    // Fetch Insurance Modal and  Check eligibility for this insurance or not
    if (newUser.isNew) await fetchInsuranceWithPayerID(newUser);
    handleSave(newUser);
    setLoading(false);
    handleClose();
  };
  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const obj = {
      ...newUser,
      ...record,
      firstName: record.first,
      lastName: record.last,
      isNew: true,
      gender: record.sex,
      phoneNumber: searchPhone(record.phone_number),
      country: record.country ? record.country : "United States",
    };
    setNewUser(obj);
  };
  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    if (phoneNo && type === "phone") {
      const phone = formatPhoneNumberIntl(data);

      const phoneArr = phone.split(" ");
      phoneArr.shift();
      phoneNo = phoneArr.join("");
    }
    let env = "";
    if (window.location.host.includes("localhost")) {
      env = "localhost";
    } else {
      env = "remote";
    }
    let record = [];
    try {
      record = await api.getPreRegisterData(phoneNo, env);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return {
            ...e,
            first: toTitleCase(e.first),
            last: toTitleCase(e.last),
          };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      const obj = { ...newUser, callTime: user.callTime, onAlert: true };
      onHandleSave(false, obj);
    } else {
      onHandleSave(false, newUser);
    }
  };
  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };

  const handleAutoShipmentConfirmation = (isConfirm) => {
    setAutoShipmentConfirmation(false);
    if (!isConfirm) {
      setNewUser({ ...newUser, autoShipment: false });
    } else {
      setNewUser({ ...newUser, autoShipment: true });
    }
  };

  const handleOpenNewPayerModal = () => {
    setOpenInsuranceModal({
      ...EMP_INS_OBJECT,
      isPrimary: newUser.insuranceDetails.length === 0,
    });
  };

  return (
    <Modal
      className={cssClass ?? ""}
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"2x2"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newUser.isNew ? `${t("createClient")}` : `${t("updateClient")}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex justify-content-between">
              <Col md="3" sm="12" className="wrapper-profile">
                <div className="card-body px-3 pt-3">
                  <div className="profile-image mt-4 position-relative text-center">
                    <div className="crew-image-wrapper">
                      <figure className="profile-image-wrapper">
                        <div className="">
                          <img src={profileImage} alt="profile-image" />
                        </div>
                      </figure>
                    </div>
                    <p
                      className="text-center mx-auto mt-3 mb-0 ellipsis"
                      style={{ maxWidth: "150px", width: "150px" }}
                    >{`${newUser.firstName} ${newUser.lastName}`}</p>
                    <small className="mb-0 text-center text-grey">{newUser.schrID}</small>
                    <br />
                    <small className="mb-0 text-center text-primary-color">{newUser.email}</small>
                  </div>
                  <hr className="horizontal-line" />
                  <div className="settings-profile">
                    <h4 className="profile-title">Settings</h4>

                    <div
                      className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                    >
                      <span>Hospice</span>
                      <SwitchToggle
                        checked={newUser.isHospice}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isHospice: e.target.checked,
                          })
                        }
                      />
                    </div>
                    {newUser.isHospice && (
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                      >
                        <span>Hospice Modifier</span>
                        <div className="d-flex justify-content-between w-40">
                          <RadioButton
                            name="GW"
                            id={`GW`}
                            label="GW"
                            checked={newUser?.codes?.hospiceModifer === "GW" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                codes: { hospiceModifer: e.target.checked ? "GW" : "" },
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            name="GV"
                            id={`GV`}
                            label="GV"
                            checked={newUser?.codes?.hospiceModifer === "GV" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                codes: { hospiceModifer: e.target.checked ? "GV" : "" },
                              })
                            }
                            disabled={editButton}
                          />
                        </div>
                      </div>
                    )}

                    {!CONFIG.isLabType && (
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                      >
                        <span>Auto Shipment</span>
                        <SwitchToggle
                          checked={newUser.autoShipment}
                          disabled={editButton}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              autoShipment: e.target.checked ? true : false,
                            })
                          }
                        />
                      </div>
                    )}
                    {!newUser.isNew && (
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                      >
                        <span>Active</span>
                        <SwitchToggle
                          checked={newUser.status === "active"}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              status: e.target.checked ? "active" : "inactive",
                            })
                          }
                        />
                      </div>
                    )}

                    <h4 className="profile-title">Communication Preference</h4>
                    <div className="d-flex align-items-center justify-content-between profile-detail mb-3">
                      <span>Result SMS</span>
                      <SwitchToggle
                        checked={newUser.sendSMS}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sendSMS: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                    >
                      <span>Result Email</span>
                      <SwitchToggle
                        checked={newUser.sendEmail}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            sendEmail: e.target.checked,
                          })
                        }
                      />
                    </div>

                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span>Billing Preferences</span>
                      <RadioButton
                        name="billingpreferences"
                        id={`billing_email`}
                        label="Email"
                        checked={newUser?.setting?.billType === "email" ? true : false}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            setting: { ...(newUser.setting || {}), billType: e.target.checked ? "email" : "" },
                          })
                        }
                        disabled={editButton}
                      />
                      <RadioButton
                        name="billingpreferences"
                        id={`billing_mail`}
                        checked={newUser?.setting?.billType === "mail" ? true : false}
                        label="Mail"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            setting: { ...(newUser.setting || {}), billType: e.target.checked ? "mail" : "" },
                          })
                        }
                        disabled={editButton}
                      />
                    </div>

                    <h4 className="profile-title">Notes</h4>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        value={newUser?.note || ""}
                        readOnly={editButton}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            note: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="9" sm="12" className="border-left-1">
                <div className="personal-info-wrapper pt-3 px-3">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="text-decoration-underline mt-0 section-title">Personal Info</h4>
                    <div style={{ display: "flex" }}>
                      <h4 className="mt-0 section-title mr-2">Outstanding Balance:</h4>
                      <h4 className="text-decoration-underline mt-0 currency-view">
                        {formatCurrency(newUser?.setting?.outstanding_balance)}
                      </h4>
                    </div>
                  </div>

                  <div className="testAdmin createClientsWrapper">
                    <div className="crew-profile-info">
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="First Name"
                        value={newUser.firstName}
                        index="firstName"
                        maxLength={100}
                        placeholder="Enter First Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            firstName: getValidDep(e.target.value),
                          })
                        }
                        required
                      />
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Middle Name"
                        value={newUser.middleName || ""}
                        index="middleName"
                        maxLength={100}
                        placeholder="Enter Middle Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            middleName: getValidDep(e.target.value),
                          })
                        }
                      />
                      <InputField
                        type="text"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Last Name"
                        value={newUser.lastName || ""}
                        index="lastName"
                        maxLength={100}
                        placeholder="Enter Last Name"
                        disabled={editButton}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            lastName: getValidDep(e.target.value),
                          })
                        }
                        required
                      />

                      <InputField
                        type="email"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Email"
                        value={newUser.email}
                        index="email"
                        maxLength={25}
                        placeholder="Enter Email"
                        disabled={editButton}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            email: convertToLower(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className={"crew-profile-row4 crew-profile-info-second-col"}>
                      <InputField
                        type="phone"
                        inputStyle={`employee-phone-input ${newUser.isNew && "pd-right-30"}`}
                        labelStyle="modalLineHeaders mt-0 text-capitalize"
                        label="Phone Number"
                        value={newUser.phoneNumber}
                        index="phoneNumber"
                        maxLength={65}
                        placeholder="Enter Phone Number"
                        disabled={editButton}
                        handleChange={handlePhoneNo}
                        required
                      />

                      <InputField
                        type="datePicker"
                        inputStyle="w-100 orderTestDob"
                        labelStyle="modalLineHeaders mt-0 text-capitalize"
                        label="Date of Birth"
                        value={
                          newUser.dob && new Date(newUser.dob) != "Invalid Date"
                            ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                            : null
                        }
                        index="dob"
                        placeholder="Date of Birth"
                        disabled={editButton}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                          })
                        }
                        minDate={new Date("1900-01-01")}
                        maxDate={new Date()}
                        required
                      />

                      <div>
                        <label className="my-0 modalLineHeaders">
                          Gender <span className="fw-bold fs-6 maroon">*</span>
                        </label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="gender"
                            id={`male${Math.random()}`}
                            label="Male"
                            checked={newUser?.gender === "M" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "M" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            name="gender"
                            id={`female${Math.random()}`}
                            checked={newUser?.gender === "F" ? true : false}
                            label="Female"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "F" : "",
                              })
                            }
                            disabled={editButton}
                          />
                          <RadioButton
                            id={`other${Math.random()}`}
                            checked={newUser?.gender === "X" ? true : false}
                            name="gender"
                            label="Not Specified"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                gender: e.target.checked ? "X" : "",
                              })
                            }
                            disabled={editButton}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="crew-profile-row4">
                      <InputField
                        type="address"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="modalLineHeaders text-capitalize"
                        label="Address"
                        value={newUser.street}
                        index="street"
                        maxLength={255}
                        placeholder="Enter Address"
                        disabled={editButton}
                        handleChange={(val, flag) => handleUpdatePatientAddress(val, flag)}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Apt/Unit#"
                        value={newUser.street2}
                        index="street2"
                        maxLength={255}
                        placeholder="Enter Apt/Unit"
                        disabled={editButton}
                        handleChange={(e) => setNewUser({ ...newUser, street2: e.target.value })}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="City"
                        value={newUser.city}
                        index="city"
                        maxLength={65}
                        placeholder="Enter City"
                        disabled={editButton}
                        handleChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                      />
                    </div>
                    <div className="crew-profile-row4">
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="State"
                        value={newUser.state}
                        index="state"
                        maxLength={getStateLength(newUser)}
                        placeholder="Enter State"
                        disabled={editButton}
                        handleChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setNewUser({
                            ...newUser,
                            state: getValidSpaces(val),
                          });
                        }}
                      />
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper w-100"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Zip Code"
                        value={newUser.zip}
                        index="zip"
                        maxLength={10}
                        placeholder="Enter Zip Code"
                        disabled={editButton}
                        handleChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setNewUser({
                            ...newUser,
                            zip: newUser.isoCode === "US" ? val : getValidSpaces(val),
                          });
                        }}
                      />

                      <InputField
                        type="dropDown"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Country"
                        options={countriesOption.filter((f) => f.value === "United States")}
                        value={
                          newUser.country
                            ? {
                                label: newUser.country,
                                value: newUser.country,
                              }
                            : null
                        }
                        index="country"
                        maxLength={20}
                        placeholder="Select Country"
                        disabled={editButton}
                        handleChange={(e) => {
                          const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                          setNewUser({
                            ...newUser,
                            country: e.value,
                            isoCode: country.code,
                          });
                        }}
                      />
                    </div>

                    <div className="testAdmin createClientsWrapper">
                      <div className="crew-profile-row4">
                        {CONFIG.isLabType && (
                          <>
                            <InputField
                              type="dropDown"
                              inputStyle="w-100 preserve-spaces"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="Rendering Provider"
                              options={providersOptions}
                              value={
                                newUser.renderingProvider
                                  ? providersOptions.find((item) => item.value === newUser.renderingProvider)
                                  : null
                              }
                              index="renderingProvider"
                              isClearable={true}
                              placeholder="Select Rendering Provider"
                              disabled={editButton}
                              handleChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  renderingProvider: e?.value || "",
                                });
                              }}
                            />{" "}
                            <InputField
                              type="dropDown"
                              inputStyle="w-100 preserve-spaces"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="Primary Care Physician"
                              options={providersOptions}
                              value={
                                newUser.primaryCarePhysician
                                  ? providersOptions.find((item) => item.label === newUser.primaryCarePhysician)
                                  : null
                              }
                              index="primaryCarePhysician"
                              placeholder="Select Primary Care Physician"
                              disabled={editButton}
                              isClearable={true}
                              handleChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  primaryCarePhysician: e?.labelVal || "",
                                });
                              }}
                            />
                            <InputField
                              type="dropDown"
                              inputStyle="w-100 preserve-spaces"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="Referring Pyhsician"
                              options={providersOptions}
                              value={
                                newUser.referringProvider
                                  ? providersOptions.find((item) => item.value === newUser.referringProvider)
                                  : null
                              }
                              index="referringPyhsician"
                              placeholder="Select Referring Pyhsician"
                              disabled={editButton}
                              isClearable={true}
                              handleChange={(e) => {
                                setNewUser({
                                  ...newUser,
                                  referringPyhsician: e?.labelVal,
                                  referringProvider: e?.value || "",
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="p-3">
                  <Row className="additional-info">
                    <Col md="4" className="testAdmin createClientsWrapper border-right-1">
                      <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                        <h4 className="text-decoration-underline mt-0 section-title">
                          Payer Information <span className="fw-bold fs-6 maroon">*</span>
                        </h4>
                        <div className="plus-icon">
                          <i
                            className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                            onClick={handleOpenNewPayerModal}
                          />
                        </div>
                      </div>
                      {newUser.insuranceDetails && newUser.insuranceDetails.length > 0
                        ? newUser.insuranceDetails.map((ins, i) => (
                            <InsuranceViewCard
                              insurance={{ ...ins }}
                              index={i + 1}
                              key={i}
                              handleEdit={(obj) => {
                                setOpenInsuranceModal({ ...obj, isNew: false });
                              }}
                              handleUpdateIns={(obj) => {
                                const findIndex = newUser.insuranceDetails.findIndex((f) => f.id === obj.id);
                                if (findIndex !== -1) {
                                  const stateUser = { ...newUser };
                                  const stateInsDetails = [...stateUser.insuranceDetails];
                                  stateInsDetails[findIndex] = obj;
                                  setNewUser({ ...stateUser, insuranceDetails: stateInsDetails });
                                }
                              }}
                              handleRemove={(obj) => {
                                const stateInsDetails = newUser.insuranceDetails.filter((f) => f.id !== obj.id);
                                setNewUser({ ...newUser, insuranceDetails: stateInsDetails });
                              }}
                              cssClass={"insCard-background-blue"}
                            />
                          ))
                        : null}
                      {!newUser.isSignUp && !importMember && !CONFIG.isLabType && (
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">{t("createLoginForClient")}</label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="login"
                              id="loginYes"
                              label="Yes"
                              disabled={editButton}
                              checked={newUser?.isMemberLogin === "Y" ? true : false}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  isMemberLogin: e.target.checked ? "Y" : "",
                                })
                              }
                            />
                            <RadioButton
                              name="login"
                              id="loginNo"
                              checked={newUser?.isMemberLogin === "N" ? true : false}
                              label="No"
                              disabled={editButton}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  isMemberLogin: e.target.checked ? "N" : "",
                                })
                              }
                            />
                          </div>
                        </Form.Group>
                      )}
                      {!newUser.isSignUp && !importMember && newUser.isMemberLogin === "Y" && (
                        <Form.Group className="w-100">
                          <label className="modalLineHeaders mt-0 text-capitalize">Password</label>
                          <Form.Control
                            className="modalInput underlinedText"
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                password: e.target.value,
                              })
                            }
                            maxLength={30}
                            value={newUser.password}
                            readOnly={editButton}
                            placeholder="Enter Password"
                            type="password"
                            autoComplete="none"
                          />
                        </Form.Group>
                      )}
                    </Col>
                    <Col md="4" className="testAdmin createClientsWrapper border-right-1">
                      <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                        <h4 className="text-decoration-underline mt-0 section-title">Additional Payer Details</h4>
                      </div>
                    </Col>
                    <Col md="4" className="additional-info-radios border-right-1">
                      <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                        <h4 className="text-decoration-underline mt-0 section-title">Documents</h4>
                        <div className="plus-icon">
                          <i
                            className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                            onClick={() =>
                              !editButton && (setSelectedDocument({ isNew: true }), setOpenDocumentModal(newUser))
                            }
                          />
                        </div>
                      </div>
                      {CONFIG.isLabType ? (
                        <DocumentList
                          list={documents}
                          handleEdit={(item) =>
                            !editButton &&
                            (setSelectedDocument({ ...item, isNew: false }), setOpenDocumentModal(newUser))
                          }
                          headerLabel={"Documents"}
                          handleDocDeleted={(doc) => dispatch(deleteDcoumentAsync(doc))}
                        />
                      ) : (
                        <>
                          <div className="w-100">
                            <div className="d-block">
                              <div className="cards-heading">
                                <span>Insurance</span> Card Front
                              </div>
                              <div className="test-details-wrapper rounded-2">
                                <div className="imageWrapper">
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    <ImageInput
                                      type="Camera"
                                      setSelectedImage={setInsuranceCardFront}
                                      selectedImage={insuranceCardFront}
                                      imageCss="insuranceImageProfileSize"
                                      disabled={editButton}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-100">
                            <div className="d-block">
                              <div className="cards-heading">
                                <span>Insurance</span> Card Back
                              </div>
                              <div className="test-details-wrapper rounded-2">
                                <div className="imageWrapper">
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    <ImageInput
                                      type="Camera"
                                      setSelectedImage={setInsuranceCardBack}
                                      selectedImage={insuranceCardBack}
                                      imageCss="insuranceImageProfileSize"
                                      disabled={editButton}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <div className="m-4">
                      <div className="d-flex flex-row align-items-center">
                        <h4 className="text-decoration-underline mt-0 section-title me-2">Proxy Profiles</h4>
                        <img
                          src={EditIcon}
                          alt="edit icon"
                          className="mb-3"
                          width="18"
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          aria-hidden="true"
                          disabled={editButton}
                          onClick={() => setProfileModal(true)}
                        />
                      </div>
                      <RelationView relations={relations} handleDeleteRelation={() => {}} isNotDeletable />
                    </div>
                  </Row>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>
      {loading && <Loader />}
      {openInsuranceModal && (
        <InsuranceDetailsModal
          type=""
          title={openInsuranceModal.isNew ? "Add Payer Information" : "Update Payer information"}
          insuranceDetails={{ ...openInsuranceModal }}
          insuranceList={newUser.insuranceDetails}
          user={newUser}
          hanndleAddIns={(obj) => {
            setOpenInsuranceModal(null);
            setNewUser({ ...newUser, insuranceDetails: parseInsDetails([...newUser.insuranceDetails, obj], newUser) });
          }}
          handleUpdateIns={(obj) => {
            let findIndex = newUser.insuranceDetails.findIndex((f) => f.id === obj.id);
            if (findIndex !== -1) {
              const stateUser = { ...newUser };

              const stateInsDetails = [...stateUser.insuranceDetails];

              if (obj.isPrimary) {
                const primaryIndex = newUser.insuranceDetails.findIndex((f) => f.isPrimary);
                if (primaryIndex !== -1) {
                  stateInsDetails[findIndex] = { ...newUser.insuranceDetails[primaryIndex] };
                  findIndex = primaryIndex;
                }
              }
              stateInsDetails[findIndex] = obj;
              setNewUser({ ...stateUser, insuranceDetails: parseInsDetails(stateInsDetails, newUser) });
            }
          }}
          handleClose={(key) => {
            setOpenInsuranceModal(null);
          }}
        />
      )}
      {openDocumentModal && (
        <DocumentForm
          emp={openDocumentModal}
          document={selectedDocument}
          handleClose={(schrID) => {
            setOpenDocumentModal(null);
            if (schrID) {
              setNewUser({ ...newUser, schrID });
            }
          }}
          setting={setting}
          cssClass="seperateModal"
        />
      )}
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {" "}
        {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
      </div>

      <Modal.Footer style={{ rowGap: "10px" }}>
        <Button
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        {!newUser.isNew && newUser.isSignUp === 1 && (
          <Button
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            disabled={editButton}
            onClick={() => (handleClose(), resetCall(true))}
          >
            Reset Password
          </Button>
        )}

        <div>
          <Button
            variant="secondary"
            className="modalButtons w-25  headerButton btn-fill me-3"
            disabled={editButton}
            onClick={() => {
              dispatch(setOpenCreatorProviderModal(true));
            }}
          >
            Add Provider
          </Button>
          <Button
            variant="secondary"
            className="modalButtons w-25  headerButton btn-fill me-3"
            disabled={editButton}
            onClick={() => {
              dispatch(setOpenInsModal(true));
              dispatch(setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() }));
            }}
          >
            Add Payer
          </Button>

          <Button
            variant="secondary"
            disabled={loading}
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(true, { ...newUser })}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
      {showProfileModal && (
        <ProfileRelationModal
          filterEmployees={appContext?.users?.filter(
            (f) => f.id !== Employee?.id && connected?.findIndex((i) => i.id === f.id) === -1
          )}
          employeesId={user?.id}
          existingRelations={relations}
          handleChangeConnceted={setConnected}
          handleChangeRelations={setRelations}
          patient={user}
          handleClose={() => {
            setProfileModal(!showProfileModal);
          }}
        />
      )}
    </Modal>
  );
};

export default React.memo(EmployeeModal, arePropsEqual);
