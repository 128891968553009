import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { NEW_PROVIDER_OBJECT } from "constant";
import { updateCompanySetting } from "store/features/companySetting/companySettingSlice";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  PROVIDER,
  PROVIDER_FETCH,
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_CLIENT,
} from "./providersContants";

// Async thunk to fetch all providers
export const fetchAllProviders = createAsyncThunk(PROVIDER_FETCH, async () => {
  const response = await api.getProviderInfo();
  return response;
});

// Async thunk to create a provider
export const createProviderAsync = createAsyncThunk(
  CREATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    let providerDefaultSetting = defaultSetting;
    const response = await api.newProviderInfo(provider);
    Object.keys(defaultSetting).map((m) => {
      providerDefaultSetting[m] = response.id;
    });
    if(providerDefaultSetting && Object.keys(providerDefaultSetting).length > 0){
      dispatch(updateCompanySetting(providerDefaultSetting));
    }
    return response;
  }
);

// Async thunk to update a provider
export const updateProviderAsync = createAsyncThunk(
  UPDATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    const response = await api.updateProviderInfo(provider);
    if(defaultSetting  && Object.keys(defaultSetting).length > 0){
      dispatch(updateCompanySetting(defaultSetting));
    }
    return response;
  }
);
export const updateProviderClientAsync = createAsyncThunk(UPDATE_PROVIDER_CLIENT, async (provider, { dispatch }) => {
  // let providerDefaultSetting = defaultSetting;
  const response = await api.updateProviderClient(provider.pId, provider.uId);
  // Object.keys(defaultSetting).map((m) => {
  //   providerDefaultSetting[m] = response.id;
  // });
  // dispatch(updateCompanySetting(providerDefaultSetting));
  return response;
});
export const updateProviderNoteAsync = createAsyncThunk(UPDATE_PROVIDER, async (provider, { dispatch }) => {
  const response = await api.updateProviderNote(provider);
  dispatch(setMessage("Provider Note Add SuccessFully!", MESSAGE_MODES.success));
  return response;
});

// Async thunk to delete a provider
export const deleteProviderAsync = createAsyncThunk(DELETE_PROVIDER, async (providerId) => {
  const response = await api.deleteProviderInfo(providerId);
  return response[0].id;
});

const providerSlice = createSlice({
  name: PROVIDER,
  initialState: {
    providers: [],
    filteredProviders: [],
    openCreator: false,
    newUser: NEW_PROVIDER_OBJECT,
  },
  reducers: {
    setFilteredProviders: (state, action) => {
      state.filteredProviders = action.payload;
    },
    setOpenCreatorProviderModal: (state, action) => {
      state.openCreator = action.payload;
    },
    setNewUserProvider: (state, action) => {
      state.newUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.filteredProviders = action.payload;
      })
      .addCase(createProviderAsync.fulfilled, (state, action) => {
        state.providers.unshift(action.payload);
        state.filteredProviders.unshift(action.payload);
      })

      .addCase(deleteProviderAsync.fulfilled, (state, action) => {
        const deletedproviderId = action.payload;
        state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
        state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
      })
      .addCase(updateProviderClientAsync.fulfilled, (state, action) => {
        console.log(state.providers);
        // const deletedproviderId = action.payload;
        // state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
        // state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
      })
      .addMatcher(
        (action) =>
          [updateProviderAsync, updateProviderNoteAsync].some((thunk) => action.type.startsWith(thunk.fulfilled.type)),
        (state, action) => {
          const updatedprovider = action.payload;
          const existingproviderIndex = state.providers.findIndex((provider) => provider.id === updatedprovider.id);
          if (existingproviderIndex !== -1) {
            state.providers[existingproviderIndex] = updatedprovider;
          }
          const existingFilteredProvidersIndex = state.filteredProviders.findIndex(
            (provider) => provider.id === updatedprovider.id
          );
          if (existingFilteredProvidersIndex !== -1) {
            state.filteredProviders[existingFilteredProvidersIndex] = updatedprovider;
          }
        }
      );
  },
});

export const { setFilteredProviders, setNewUserProvider, setOpenCreatorProviderModal } = providerSlice.actions;

export default providerSlice.reducer;
