import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import { INSURANCE_PERSONALIZE } from "../../constants/personalization/InsPersonalization";
import { insurnaceSelector, totalInsurancesRecordsSelector } from "../../store/features/insurances/insuranceSelectors";
import { selectedCompanySetting } from "../../store/features/companySetting/companySettingSelectors";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { t } from "../../stringConstants";
import Filter from "components/Filter";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import ExportToExcel from "components/ExportToExcel";
import {
  fetchInsurancesAsync,
  setInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
} from "../../store/features/insurances/insuranceSlice";
import { debounce } from "debounce";
import InsuranceModal from "../../components/Insurances/InsuranceModal";
import { INSURANCE_EMPTY_OBJECT, PAGE_LIMIT, TABLE_QUICK_TOOLS } from "../../constant";
import MainTable from "../../components/Table/MainTable";
import { draggablePersonalizationLocalStorage, formatCurrency, userCompanyID, formatNumber } from "../../utils";
import api from "../../api";
import RedirectLink from "components/RedirectLink/RedirectLink";
import MFPagination from "components/Pagination/MFPagination";
import TDStatus from "components/Table/TDStatus";

const Insurances = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();
  const { insurancesList, totalInsurancesRecords, filteredInsurances } = useSelector(insurnaceSelector);
  const [insuranceData, setInsuranceData] = useState([]);
  const setting = useSelector(selectedCompanySetting);
  const loginUser = useSelector(selectLoginUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const totalInsurances = useSelector(totalInsurancesRecordsSelector);

  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const searchTerms = [
    "Ins Name",
    "Ins Alias",
    "Electronic Claims",
    "Electronic Eligibility",
    "Electronic ERA",
    "Insurance Company",
    "Active"
  ];

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending, page) => {
      dispatch(fetchInsurancesAsync({ ...filter, orderBy: sortBy, orderByDirection: sortDescending, page: page }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(loginUser, "insurance", INSURANCE_PERSONALIZE);

    setPersonalize(response);
  }, [setting]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const insurancesToMap = useMemo(() => {
    const list = insuranceData ? [...insuranceData] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [insuranceData, currentPage]);

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setOpenInsuranceModal(true));
        dispatch(setSelectedInsurance({ ...row, isNew: false }));
        break;

      default:
        break;
    }
  };
  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "insurance");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(loginUser.phone_number, personalisationData);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = insuranceData.length;
    if (direction === "next" && page === totalPages && totalInsurances > 0 && currentRecord < totalInsurances) {
      setPageNo(pageNo + 1);
    }
  };

  useEffect(() => {
    if (pageNo > 1) {
      fetchFromApi(filter, sortBy, sortDescending, pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    if (pageNo > 1) {
      const concatResult = insuranceData.concat(filteredInsurances);
      setInsuranceData(concatResult);
    } else {
      setInsuranceData(filteredInsurances);
    }
  }, [filteredInsurances]);

  const renderTd = (item, row) => {
    if (item.itemKey === "isActive") return  <TDStatus row={row} item={item.itemKey} />;
    if (item.itemKey === "Name" || item.itemKey === "shortName" ) {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
            }}
          >
            {row[item.itemKey]}
          </RedirectLink>
        </td>
      );
    }
    
    if (item.itemKey === "claims") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
            }}
          >
            {row.claims || 0}
          </RedirectLink>
        </td>
      );
    }

    if (item.itemKey === "claimAmount") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
            }}
          >
            {formatCurrency(row.claimAmount)}
          </RedirectLink>
        </td>
      );
    }

    if (item.itemKey === "openAmount") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
              filterTerm: "status",
              filterName: "open",
            }}
          >
            {formatCurrency(row.openAmount)}
          </RedirectLink>
        </td>
      );
    }
    if (item.itemKey === "paid") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
              filterTerm: "status",
              filterName: "paid",
            }}
          >
            {formatCurrency(row.paid)}
          </RedirectLink>
        </td>
      );
    }
    if (item.itemKey === "totalAppeal") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.Name,
              term: "payer_name",
              filterTerm: "status",
              filterName: "appeal",
            }}
          >
            {formatCurrency(row.totalAppeal)}
          </RedirectLink>
        </td>
      );
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("payerManagement")} ({insuranceData ? formatNumber(totalInsurancesRecords) : insurancesList.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={insuranceData.map((e) => {
                      return {
                        ...e,
                      };
                    })}
                    filter={filter}
                    title={t("payerManagement")}
                    sortBy={sortBy}
                    exportType="Payer"
                  />

                  <Icon
                    handleClick={() => {
                      dispatch(setOpenInsuranceModal(true));
                      dispatch(setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() }));
                    }}
                    title={"Create Insurance"}
                    label={"Create"}
                    iconType={"createIcon"}
                  />
                </div>
                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} personalisationData={INSURANCE_PERSONALIZE} />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={totalPages} />
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={insuranceData.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={insurancesToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"insurance"}
                  tools={quickTools}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={130}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {openPersonalizationModal && (
          <PersonalizationModal
            data={JSON.stringify(personalize)}
            handleChange={handlePersonalization}
            show={openPersonalizationModal}
            handleClose={() => setOpenPersonalizationModal(false)}
          />
        )}
      </>
    </div>
  );
};

export default Insurances;
