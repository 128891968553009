import React, { useState, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { INSURANCE_PROVIDER, INSURANCE_TYPE, MEDICATRE_IDS } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { convertToUpper } from "utils";
import { CUSTOM_INS } from "constant";
import { isValidIDNumber } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import InputField from "components/InputField/InputField";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import api from "api";
import { useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { insurnaceSelector } from "store/features/insurances/insuranceSelectors";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";

const InsuranceDetailsModal = (props) => {
  const { handleClose, hanndleAddIns, handleUpdateIns, title, type, insuranceDetails, insuranceList, user } = props;

  const loginUser = useSelector(selectLoginUser);

  const { payerList } = useSelector(insurnaceSelector);

  const insuranceCompanies = ["00001", "00002", "00003"];

  const [insuranceData, setInsuranceData] = useState(insuranceDetails);

  const [showError, setShowError] = useState("");

  const InsuranceObject = useMemo(() => {
    if (insuranceData.insuranceCompany) {
      const item = payerList.find((i) => i.value === insuranceData.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [insuranceData]);

  const alreadyExistIns = (ins, isUpdate) => {
    const existIns = insuranceList.filter((f) => {
      if (isUpdate)
        return (
          f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo && f.id !== insuranceDetails.id
        );
      return f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo;
    });
    if (existIns.length > 0) {
      return true;
    }
    return false;
  };

  const handleInsValidation = (insuranceData) => {
    if (!insuranceData.insuranceCompany) {
      setShowError("Insurance is required");
      return;
    }

    if (!CUSTOM_INS.includes(insuranceData.insuranceCompany)) {
      if (!insuranceData.medicalNo || !insuranceData.medicalNo.trim()) {
        setShowError(`${insuranceData.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is required`);
        return;
      }

      if (!isValidIDNumber(insuranceData.medicalNo)) {
        setShowError(`${insuranceData.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
        return;
      }

      if (insuranceData.isMedicare === "Y" && !insuranceData.medicareNo) {
        setShowError("Medicare Number Not be Empty");
        return;
      }
      // if (insuranceData.isMedicare === "Y" && !isValidIDNumber(insuranceData.medicareNo)) {
      //   setShowError("Medicare Number is invalid");
      //   return;
      // }
      if (alreadyExistIns(insuranceData, insuranceDetails.id ? true : false)) {
        setShowError("Already add Same Insurance!");
        return;
      }
    }
    return true;
  };

  const handelInsuracneChange = async (e) => {
    setInsuranceData({
      ...insuranceData,
      insuranceCompany: e.value,
      insuranceCompanyCode: e.Name,
      insuranceType: e.payer_type,
      insuranceGroupId: "",
      isMedicare: "N",
    });
  };

  const handleSave = async () => {
    let eligibiltyResponse = null;

    if (!CUSTOM_INS.includes(insuranceData.insuranceCompany) && !user.isNew) {
      const modifiedEmp = {
        ...user,
        insuranceCompany: insuranceData.insuranceCompany,
        medicalNo: insuranceData.medicalNo,
      };

      const { data } = await api.checkManualEligibility(modifiedEmp, loginUser);
      if (data) {
        setInsuranceData({
          ...insuranceData,
          copay: data.copay,
          deductible: data.deductible,
          coinsurnace: data.coinsurnace,
        });

        eligibiltyResponse = {
          copay: data.copay,
          deductible: data.deductible,
          coinsurnace: data.coinsurnace,
        };
      }
    }

    const insurance = {
      copay: 0,
      deductible: 0,
      isActive: true,
      medicalNo: "",
      medicareNo: "",
    };

    const insuranceCopy = insuranceCompanies.includes(insuranceData.insuranceCompany)
      ? { ...insuranceData, ...insurance }
      : {
          ...insuranceData,
          ...(eligibiltyResponse && { ...eligibiltyResponse }),
        };

    if (!handleInsValidation(insuranceCopy)) return;
    const insData = { ...insuranceCopy };

    if (insuranceCopy.isNew) {
      Object.assign(insData, { id: "prefix_" + Math.random().toString(36).slice(2, 9) });
      hanndleAddIns(insData);
    } else {
      handleUpdateIns(insData);
    }
    handleClose();
  };

  const showInsuranceDetails = useMemo(
    () => !insuranceCompanies.includes(insuranceData.insuranceCompany),
    [insuranceData.insuranceCompany]
  );

  const handelAdvantatePlan = (e) => {
    const isChecked = e.target.checked;
    const label = isChecked ? "Y" : "N";
    setInsuranceData({
      ...insuranceData,
      isMedicare: label,
    });
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper d-block">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 mt-4"
              inputStyle="w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Payer"
              options={payerList}
              value={InsuranceObject}
              index="insuranceCompany"
              placeholder="Select Payer"
              handleChange={handelInsuracneChange}
              required
            />

            {insuranceData.insuranceCompany &&
              showInsuranceDetails &&
              MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) === -1 && (
                <>
                  <InputField
                    type="text"
                    groupWrapper="form-group-wrapper me-2"
                    inputStyle="modalInput"
                    labelStyle="mt-0 modalLineHeaders text-capitalize"
                    label="Member ID"
                    value={insuranceData.medicalNo}
                    index="medicalNo"
                    maxLength={50}
                    placeholder="Member ID"
                    handleChange={(e) =>
                      setInsuranceData({
                        ...insuranceData,
                        medicalNo: convertToUpper(e.target.value),
                      })
                    }
                  />

                  <InputField
                    type="text"
                    groupWrapper="form-group-wrapper me-2"
                    inputStyle="modalInput"
                    labelStyle="mt-0 modalLineHeaders text-capitalize"
                    label="Group ID"
                    value={insuranceData.insuranceGroupId}
                    index="insuranceGroupId"
                    maxLength={50}
                    placeholder="Enter Group ID"
                    handleChange={(e) =>
                      setInsuranceData({
                        ...insuranceData,
                        insuranceGroupId: convertToUpper(e.target.value),
                      })
                    }
                  />

                  {/* <Form.Group className="form-group-wrapper">
                    <label className="modalLieHeaders emp-gender-mb text-capitalize">
                      Is the Insurance Information Entered an Advantage Plan?
                    </label>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <RadioButton
                        name="secondaryMedicare"
                        id="syes"
                        label="Yes"
                        checked={insuranceData.isMedicare === "Y" ? true : false}
                        handleChange={(e) =>
                          setInsuranceData({
                            ...insuranceData,
                            isMedicare: e.target.checked ? "Y" : "",
                          })
                        }
                      />
                      <RadioButton
                        name="secondaryMedicare"
                        id="sno"
                        checked={insuranceData?.isMedicare === "N" ? true : false}
                        label="No"
                        handleChange={(e) =>
                          setInsuranceData({
                            ...insuranceData,
                            isMedicare: e.target.checked ? "N" : "",
                            medicareNo: e.target.checked ? "" : insuranceData.medicareNo,
                          })
                        }
                      />
                    </div>
                  </Form.Group> */}
                </>
              )}

            {insuranceData.isMedicare === "Y" && showInsuranceDetails && (
              <InputField
                type="text"
                groupWrapper="form-group-wrapper me-2"
                inputStyle="modalInput"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="Medicare Number"
                value={insuranceData.medicareNo}
                index="medicareNo"
                maxLength={50}
                placeholder="Enter Medicare Number"
                handleChange={(e) =>
                  setInsuranceData({
                    ...insuranceData,
                    medicareNo: convertToUpper(e.target.value),
                  })
                }
              />
            )}

            {insuranceData.insuranceCompany &&
              showInsuranceDetails &&
              MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) !== -1 && (
                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Medicare Number"
                  value={insuranceData.medicareNo}
                  index="medicareNo"
                  maxLength={50}
                  placeholder="Enter Medicare Number"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicalNo: convertToUpper(e.target.value),
                      medicareNo: convertToUpper(e.target.value),
                    })
                  }
                />
              )}

            {insuranceData.insuranceCompany && showInsuranceDetails && (
              <>
                <CurrencyInput
                  value={insuranceData.copay}
                  onChange={(e) => {
                    setInsuranceData({
                      ...insuranceData,
                      copay: e,
                    });
                  }}
                  label="Copay"
                  placeholder="Copay"
                />
                <CurrencyInput
                  value={insuranceData.deductible}
                  onChange={(e) => {
                    setInsuranceData({
                      ...insuranceData,
                      deductible: e,
                    });
                  }}
                  label="Deductible"
                  placeholder="CoDeductiblepay"
                />
              </>
            )}
            {insuranceData?.insuranceCompany &&
              !CUSTOM_INS.includes(insuranceData?.insuranceCompany) &&
              !MEDICATRE_IDS.includes(insuranceData.insuranceCompany) && (
                <div className="d-flex align-items-center justify-content-between column-gap-10 profile-detail mb-3 mr-50-per">
                  <label>Advantage Plan: </label>
                  <SwitchToggle
                    checked={insuranceData.isMedicare === "Y" ? true : false}
                    handleChange={handelAdvantatePlan}
                    color={"blue"}
                  />
                </div>
              )}
            <div className="d-flex align-items-center justify-content-between column-gap-10 profile-detail mb-3 mr-50-per">
              <label>Primary: </label>
              <SwitchToggle
                checked={insuranceData.isPrimary}
                handleChange={(e) =>
                  !insuranceDetails.isPrimary &&
                  setInsuranceData({
                    ...insuranceData,
                    isPrimary: e.target.checked,
                  })
                }
                color={"blue"}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {showError && <ErrorMessage error={showError} handleChange={() => setShowError("")} />}
        </div>
        <Modal.Footer>
          <div className="saveButton">
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleSave();
              }}
            >
              {insuranceDetails.isNew ? "Add" : "Update"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InsuranceDetailsModal;
