/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
// import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/tableStyling.css";
import AdminLayout from "layouts/Admin.js";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignOut,
  AmplifySignUp,
  withAuthenticator,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { isValidPhone } from "utils";
import App from "App";

Amplify.configure(awsconfig);

document.documentElement.classList.add("blue");

ReactDOM.render(
  <div>
    <App />
  </div>,
  document.getElementById("root")
);
