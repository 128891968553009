import { TABLE_COLUMN_TYPES, claimSubmitStatusColorOpts } from "constant";

export const NEW_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    customCellRenderer: true,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    customCellRenderer: true,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "locationName",
    title: "Lab Client",
    textAlign: "left",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "street2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "insuranceCompany",
    title: "Payer",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Insurance #",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "insuranceGroupId",
    title: "Group ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "schrID",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "testOrdered",
    title: "Test Ordered",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "testAvailable",
    title: "Test Available",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "updatedAt",
    title: "Last Update",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    type: TABLE_COLUMN_TYPES.longDate,
  },
  {
    itemKey: "eligibilityStatus",
    title: "Eligibility",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    customCellRenderer: true,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    customCellRenderer: true,
  },
];

export const LAB_MEMBER_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    customCellRenderer: true,
  },
  {
    itemKey: "middleName",
    title: "Middle Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    customCellRenderer: true,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    customCellRenderer: true,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "street2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "insuranceCompany",
    title: "Payer",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Member ID",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "insuranceGroupId",
    title: "Group ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "schrID",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    type: TABLE_COLUMN_TYPES.labelID,
  },
  {
    itemKey: "updatedAt",
    title: "Last Update",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    type: TABLE_COLUMN_TYPES.longDate,
  },
  {
    itemKey: "eligibilityStatus",
    title: "Eligibility",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    customCellRenderer: true,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    customCellRenderer: true,
  },
  {
    itemKey: "providerName",
    title: "Provider",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "primaryCarePhysician",
    title: "Primary Care Physician",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "referringPyhsician",
    title: "Referring Physician",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const CLIENT_PERSONALIZE = [
  {
    itemKey: "name",
    title: "Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "monthToDate",
    title: "Total MTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    customCellRenderer: true,
  },
  {
    itemKey: "yearToDate",
    title: "Total YTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    customCellRenderer: true,
  },
  {
    itemKey: "totalOrders",
    title: "Total ITD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    customCellRenderer: true,
  },
  {
    itemKey: "contact_name",
    title: "Contact Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contact_email",
    title: "Contact Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 10,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phone_number",
    title: "Contact Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 11,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "testOrderCategory",
    title: "Order Category",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 12,
    customCellRenderer: true,
  },
  {
    itemKey: "testLimit",
    title: "Test Limit",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "minTestOrderQty",
    title: "Min Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 14,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const BRM_CLIENT_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Created At",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "name",
    title: "Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    customCellRenderer: true,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "updatedAt",
    title: "Updated At",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 10,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const BRM_LOCATION_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Created At",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "name",
    title: "Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contact_name",
    title: "Contact Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contact_email",
    title: "Contact Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phone_number",
    title: "Contact Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    type: TABLE_COLUMN_TYPES.phone,
  },
];

export const LOCATION_PERSONALIZE = [
  {
    itemKey: "name",
    title: "Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "monthToDate",
    title: "Total MTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    customCellRenderer: true,
  },
  {
    itemKey: "yearToDate",
    title: "Total YTD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    customCellRenderer: true,
  },
  {
    itemKey: "totalOrders",
    title: "Total ITD",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    customCellRenderer: true,
  },
  {
    itemKey: "contact_name",
    title: "Contact Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contact_email",
    title: "Contact Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 10,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phone_number",
    title: "Contact Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 11,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "testOrderCategory",
    title: "Order Category",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 12,
    customCellRenderer: true,
  },
  {
    itemKey: "testLimit",
    title: "Test Limit",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "minTestOrderQty",
    title: "Min Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 14,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "preRegisterationLink",
    title: "Pr Link",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 15,
    customCellRenderer: true,
  },
];
export const LAB_CLIENT_PERSONALIZE = [
  {
    itemKey: "practiceID",
    title: "ID",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "name",
    title: "Practice Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "phone_number",
    title: "Phone",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "fax",
    title: "Fax",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contact_name",
    title: "Main Contact",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contactName",
    title: "Contact Phone",
    textAlign: "left",
    isCheck: false,
    textOverflow: "none",
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "contactFax",
    title: "Contact Fax",
    textAlign: "center",
    isCheck: false,
    textOverflow: "none",
    id: 9,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "contact_email",
    title: "Contact Email",
    textAlign: "center",
    isCheck: false,
    textOverflow: "none",
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "discount",
    title: "Discount %",
    textAlign: "center",
    isCheck: false,
    textOverflow: "none",
    id: 11,
    type: TABLE_COLUMN_TYPES.percentage,
  },
  {
    itemKey: "commission",
    title: "Commission %",
    textAlign: "center",
    isCheck: false,
    textOverflow: "none",
    id: 12,
    type: TABLE_COLUMN_TYPES.percentage,
  },

  {
    itemKey: "totalBilledAmount",
    title: "Total Billed",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 18,
    customCellRenderer: true,
  },

  {
    itemKey: "claimAmount",
    title: "Total Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 14,
    customCellRenderer: true,
  },

  {
    itemKey: "paid",
    title: "Total Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 16,
    type: TABLE_COLUMN_TYPES.currency,
    customCellRenderer: true,
  },

  {
    itemKey: "totalOrders",
    title: "Total Claims",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },

  {
    itemKey: "openAmount",
    title: "Claims Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 15,
    type: TABLE_COLUMN_TYPES.number,
  },

  {
    itemKey: "associatedClients",
    title: "Associated Providers",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 17,
    type: TABLE_COLUMN_TYPES.number,
    style: {
      color: "blue",
      cursor: "pointer",
    },
  },
  {
    itemKey: "network",
    title: "Network",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 18,
    type: TABLE_COLUMN_TYPES.text,
  },
  // {
  //   itemKey: "paid",
  //   title: "Sales Coverage",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 17,
  //   type: TABLE_COLUMN_TYPES.text,
  // },
];

export const ORDER_PERSONALIZE = [
  {
    itemKey: "orderDate",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    customCellRenderer: true,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    customCellRenderer: true,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payerId",
    title: "Primary Payer",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Primary Payer Number",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "testQty",
    title: "Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: "linkedText",
  },
  {
    itemKey: "orderId",
    title: "Order ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pcn",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    type: TABLE_COLUMN_TYPES.text,
  },
  // {
  //   itemKey: "claim",
  //   title: "Claim Submitted",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 16,
  // },
  // {
  //   itemKey: "claimStatus",
  //   title: "Claim Status",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 17,
  // },
  // {
  //   itemKey: "InsuranceStatus",
  //   title: "Ins Status",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 18,
  // },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 16,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "siteName",
    title: "Facility/Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 17,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 18,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "trackingNumber",
    title: "Tracking ID",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 19,
    customCellRenderer: true,
  },
  {
    itemKey: "requestedByName",
    title: "Ordered By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const Billing_PERSONALIZE = [
  {
    itemKey: "orderDate",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "claimSubmitDate",
    title: "Claim Submitted",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "clientName",
    title: "Client",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "siteName",
    title: "Facility/Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payerId",
    title: "Primary Payer",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 14,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Primary Insurance Number",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 15,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "testQty",
    title: "Order Qty",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 16,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: "linkedText",
  },

  {
    itemKey: "claimStatus",
    title: "Claim Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    customCellRenderer: true,
  },
  {
    itemKey: "InsuranceStatus",
    title: "Ins Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    customCellRenderer: true,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pcn",
    title: "LTCS ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "employeeClaimID",
    title: "Claim ID",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: "claimAmount",
    title: "Claim Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "expectedAmount",
    title: "Ex. Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "amountRecieved",
    title: "Claim Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "amountRecievedDate",
    title: "Amount Received Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 25,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "chequeNo",
    title: "ERA",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 26,
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: "requestedByName",
    title: "Ordered By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 27,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const MEMBER_ORDER_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Order Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "testQty",
    title: "Qty Ordered",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "orderStatus",
    title: "Order Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Requested",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "locationName",
    title: "Facility/Agent",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "subAgentName",
    title: "Sub Agent",
    textAlign: "",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payerId",
    title: "Payer",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Member ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: "groupId",
    title: "Group ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    type: TABLE_COLUMN_TYPES.upperCaseText,
    customCellRenderer: true,
  },

  {
    itemKey: "message",
    title: "Status",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
    customCellRenderer: true,
  },
  {
    itemKey: "userName",
    title: "User",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "copay",
    title: "Co Payment",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "deductible",
    title: "Deductible",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "snf",
    title: "SNF",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "coinsurnace",
    title: "Coinsurnace",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "planNumber",
    title: "Plan Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    type: TABLE_COLUMN_TYPES.number,
  },
];

export const LAB_ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Requested",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payerId",
    title: "Payer",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: "medicalNo",
    title: "Member ID #",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: "message",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    customCellRenderer: true,
  },
  {
    itemKey: "userName",
    title: "User",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "view",
    title: "Details",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: "copay",
    title: "Co Payment",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "deductible",
    title: "Deductible",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "snf",
    title: "SNF",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "coinsurnace",
    title: "Coinsurance",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "planNumber",
    title: "Plan Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
];

export const USER_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phone_number",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "userRole",
    title: "Role",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    customCellRenderer: true,
  },
  {
    itemKey: "note",
    title: "Associated",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const PROVIDER_PERSONALIZE = [
  {
    itemKey: "name",
    title: "Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    customCellRenderer: true,
    type: "",
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "code",
    title: "Code",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "fax",
    title: "Fax",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "npi",
    title: "NPI",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "npiType",
    title: "NPI Type",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "speciality",
    title: "Speciality",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "taxid",
    title: "Taxonomy",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "orders",
    title: "Total Orders",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "claimAmount",
    title: "Total Billed",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    customCellRenderer: true,
  },
  {
    itemKey: "openAmount",
    title: "Total Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    customCellRenderer: true,
  },
  {
    itemKey: "paid",
    title: "Total Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    customCellRenderer: true,
  },
  {
    itemKey: "lastOrder",
    title: "Last Order",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
];

export const EMP_CLAIM_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Claim Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "pat_name_f",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_name_l",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "pat_phone",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "pat_addr_1",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "pat_addr_2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "pat_zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_dob",
    title: "DOB",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "pat_sex",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payer_name",
    title: "Member",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "ins_number",
    title: "Member ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "claimNo",
    title: "Claim ID",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "total_charge",
    title: "Claim Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "adjustedAmount",
    title: "Adjusted Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "eraIds",
    title: "ERA",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    width: "100%",
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: "from_date_1",
    title: "DOS",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "paid_ins_processed",
    title: "Paid By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "remote_claimid",
    title: "B-ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
    width: "100%",
    type: TABLE_COLUMN_TYPES.labelID,
  },
  {
    itemKey: "submittedByName",
    title: "Processed By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "checkDate",
    title: "Paid Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 25,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "paymentCheckNo",
    title: "Cheque No",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 26,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "accNo",
    title: "Acc. No",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 27,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "invoiceID",
    title: "Invoice ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 28,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "invoiceStatus",
    title: "Invoice Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    colorObj: claimSubmitStatusColorOpts,
    id: 29,
    width: "100%",
    type: TABLE_COLUMN_TYPES.statusFormatter,
  },
];

export const CLAIM_INVOICE_PERSONALIZE = [
  {
    itemKey: "invoiceDate",
    title: "Invoice Date",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "invoiceAmount",
    title: "Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "discount",
    title: "Discount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "dueDate",
    title: "Due Date",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 10,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "paymentMethod",
    title: "Payment Method",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 11,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 12,
    width: "100%",
    type: TABLE_COLUMN_TYPES.statusFormatter,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },

  {
    itemKey: "invoiceNo",
    title: "Invoice No",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "postGridStatus",
    title: "Sent",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 14,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "sendTo",
    title: "Sent To",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 15,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const DASHBOARD_EMP_CLAIM_PERSONALIZE = [
  {
    itemKey: "pat_full_name",
    title: "Full Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    customCellRenderer: true,
  },
  {
    itemKey: "pat_phone",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "submissionDate",
    title: "Date Sent",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    customCellRenderer: true,
  },
  {
    itemKey: "ins_number",
    title: "Member ID",
    textAlign: "",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "payer_name",
    title: "Member",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "total_charge",
    title: "Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  // {
  //   itemKey: "employeeClaimID",
  //   title: "Claim ID",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 7,
  //   type: TABLE_COLUMN_TYPES.text,
  // },
  // {
  //   itemKey: "era",
  //   title: "View Era",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 8,
  //   width: "100%",
  //   type: TABLE_COLUMN_TYPES.text,
  // },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
];

export const INSURANCE_PAID_CLAIM_DETAILS_TABLE = [
  {
    itemKey: "from_date_1",
    title: "DOS",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "reSubmissionDate",
    title: "Date Sent",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "total_charge",
    title: "Total Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
];

export const CLAIM_STATUS_DETAILS_VIEW_TABLE = [
  {
    itemKey: "payerName",
    title: "Payer Name",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "count",
    title: "Total Claims",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "ttlcharge",
    title: "Total Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
];

export const PAID_CLAIM_DETAILS_TABLE = [
  {
    itemKey: "payer_name",
    title: "Payer Name",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "count",
    title: "Total Claims",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "ttlcharge",
    title: "Total Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "era",
    title: "View Era",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const DOCUMENT_PERSONALIZATION = [
  {
    itemKey: "createdAt",
    title: "Upload Date",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "associatedClaim",
    title: "Associated Claim",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    customCellRenderer: true,
  },
  {
    itemKey: "docType",
    title: "Document Type",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "docLabel",
    title: "Document Label",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "updatedByName",
    title: "Uploaded By",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "fileName",
    title: "View Document",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.image,
  },
];

export const BULK_CLAIM_PERSONALIZE = [
  {
    itemKey: "reSubmissionDate",
    title: "Date Sent",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "payer_name",
    title: "Payer",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "claimStatus",
    title: "Claim Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
  },

  {
    itemKey: "errorMessage",
    title: "Send Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "disclaimer",
    title: "Disclaimer",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "action",
    title: "Action",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,

    customCellRenderer: true,
  },
];

export const CLIENT_PCS_PERSONALIZE = [
  {
    itemKey: "internalCode",
    title: "Internal Code",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "description",
    title: "Description",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "charges",
    title: "Charges",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "action",
    title: "Action",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,

    customCellRenderer: true,
  },
];

export const APPEAL_MODAL_VIEW_TABLE = [
  {
    itemKey: "payerid",
    title: "Payer Id",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "payer_name",
    title: "Payer Name",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "proc_code",
    title: "Proc Code",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "view",
    title: "",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,

    customCellRenderer: true,
  },
];
export const SALES_PERSON_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 0,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "street",
    title: "Street",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: "zip",
    title: "Zip",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "commission",
    title: "Comission",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
    width: "100%",
    type: TABLE_COLUMN_TYPES.percentage,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    width: "100%",
    customCellRenderer: true,
  },
  {
    itemKey: "practiceID",
    title: "Practice ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const HOSPICE_DETAILS_PERSONALIZE = [
  {
    itemKey: "planStartDate",
    title: "Start Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "planEndDate",
    title: "End Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "benefit_notes",
    title: "Notes",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  // {
  //   itemKey: "benefit_description",
  //   title: "Description",
  //   textAlign: "center",
  //   isCheck: true,
  //   textOverflow: "visible",
  //   id: 1,
  //   width: "100%",
  //   type: TABLE_COLUMN_TYPES.text,
  // }
];

export const PROCEDURE_CODE_PERSONALIZE = [
  {
    itemKey: "code",
    title: "Procedure Code",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "internalCode",
    title: "Alias",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "description",
    title: "Procedure Code Desc",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "charges",
    title: "Internal Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "referenceCharge",
    title: "Reference Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "cashCharge",
    title: "Cash Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "inNeworkCharge",
    title: "In Network Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "outNetworkCharge",
    title: "Out Network Charge",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "effectiveDate",
    title: "Effective Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "retiredDate",
    title: "Inactive Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 10,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: "lastUsed",
    title: "Last Used",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 11,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];

export const DIAG_CODE_PERSONALIZE = [
  {
    itemKey: "code",
    title: "Diagnosis Code",
    isCheck: true,
    textOverflow: "visible",
    id: 0,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "internalCode",
    title: "Alias",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "title",
    title: "DIAGNOSIS CODE DESC",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "effectiveDate",
    title: "Effective Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "retiredDate",
    title: "Date Inactive",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: "lastUsed",
    title: "Last Used",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];

export const MESSAGE_TEMPLATE_PERSONALIZE = [
  {
    itemKey: "name",
    title: "Name",
    isCheck: true,
    textOverflow: "visible",
    id: 0,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "category",
    title: "Category",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "subject",
    title: "Subject",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "message",
    title: "Message",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const DIAGNOSIS_CODE_PERSONALIZE = [
  {
    itemKey: "code",
    title: "Diagnosis Code",
    isCheck: true,
    textAlign: "left",
    textOverflow: "visible",
    id: 0,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "internalCode",
    title: "Alias",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "title",
    title: "Diagnosis Code Desc",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];
export const INVOICE_DETAILS_FILTER_PERSONALISATION = [
  {
    itemKey: "from_date",
    title: "Date Of Service",
  },
  {
    itemKey: "first_name",
    title: "Patient Name",
  },
  {
    itemKey: "referring_provider",
    title: "Reffering Provider",
  },
  {
    itemKey: "proc_code",
    title: "CPT",
  },
  {
    itemKey: "accession_no",
    title: "Accession No",
  },
];

export const INVOICE_SETTING_PERSONALISATION = [
  {
    itemKey: "attemptNo",
    title: "Attempt No",
    isCheck: true,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "noOfDays",
    title: "No of Days",
    isCheck: true,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "action",
    title: "Action",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,

    customCellRenderer: true,
  },
];
