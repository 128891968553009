import React, { useState, useContext, useMemo, useCallback } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { debounce } from "debounce";
import {
  ELIGIBILITY_HEADERS,
  INSURANCE_PROVIDER,
  PAGE_LIMIT,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_SUBAGENT,
  USER_TYPE_USER,
  CONFIG,
  CHECK_ELIGIBILITY_OPTIONS,
  CHECKELIGIBILITY,
  ADMIN_USERS,
  TABLE_QUICK_TOOLS,
  MESSAGE_MODES,
} from "../constant";
import { openEligibilityDetailModalAsync } from "store/features/checkEligibility/checkEligibilitySlice";
import { ELIGIBILITY_PERSONALIZE, LAB_ELIGIBILITY_PERSONALIZE } from "constants/personalization";
import {
  formatOrder,
  downloadDataAsCSV,
  isValidIDNumber,
  userCompanyID,
  isValidDobField,
  currentDate,
  getValidDep,
  formatNumber,
  draggablePersonalizationLocalStorage,
  convertToUpper,
} from "../utils";
import { t } from "../stringConstants";
import MFPagination from "components/Pagination/MFPagination";
import Loader from "components/Loader/Loader";
import "../../src/tableStyling.css";
import OrderModal from "components/Modal/Order/OrderModal";
import { Storage } from "aws-amplify";
import moment from "moment";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import { useLocation } from "react-router-dom";
import CheckEligibilityModal from "components/Modal/CheckEligibility/CheckEligibilityModal";
import EligibilityDetailsModal from "components/Modal/CheckEligibility/EligibilityDetailsModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import Icon from "components/Icon";
import MainTable from "components/Table/MainTable";
import Status from "components/Status";
import { fetchEligibityList } from "store/features/checkEligibility/checkEligibilityActions";
import {
  setPageNo,
  setFilteredEligibilities,
  updateNotes,
} from "store/features/checkEligibility/checkEligibilitySlice";
import { checkEligibiitSelector } from "store/features/checkEligibility/checkEligibilitySelectors";
import { useDispatch, useSelector } from "react-redux";
import { openCheckEligibilitySelector } from "store/features/checkEligibility/checkEligibilitySelectors";
import { setOpenCheckEligibilityModal } from "store/features/checkEligibility/checkEligibilitySlice";
import { setEligibilityDetailsData } from "store/features/checkEligibility/checkEligibilitySlice";
import { setMessage } from "store/features/general/generalAction";
import NotesModal from "components/Modal/NotesModal";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";

const checkEligibility = (props) => {
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newItem, setNewItem] = useState({ scheduleName: "" });
  const [loading, setLoading] = useState(false);
  const [csvUploadLoading, setCsvUploadLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [orderModal, setOrderModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [showEligibilityDetails, setShowEligibilityDetails] = useState(false);
  const [selectedEligibility, setSelectedEligibility] = useState(null);
  const searchTerms = ["Date", "First Name", "Last Name", "Lab Client", "Payer", "Group ID", "ID Number", "Status"];
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [title, setTitle] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [assignProgram, setAssignProgram] = useState([]);
  const [noteModal, setNoteModal] = useState(null);
  const loginUser = useSelector(selectLoginUser);

  const dispatch = useDispatch();
  const openCheckEligibilityModal = useSelector(openCheckEligibilitySelector);
  const { eligibilities, error, totalRecord, pageNo, filteredEligibilities } = useSelector(checkEligibiitSelector);

  useEffect(() => {
    if (location.state) {
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
      }
    }
  }, [location.state]);

  useEffect(() => {
    setPersonalize(
      draggablePersonalizationLocalStorage.get(
        appContext.user,
        "checkEligibility",
        CONFIG.isLabType ? LAB_ELIGIBILITY_PERSONALIZE : ELIGIBILITY_PERSONALIZE
      )
    );
  }, []);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await api.getLoggedInUserEmployeeID(null, appContext.user.preferred_username);
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await api.getOrders(userType, id);

    const formatttedOrders = data.map((o) => formatOrder(o));
    setOrders(formatttedOrders);
    setfilteredEligibilities(sortData(nestedFilter(formatttedOrders, filter), sortBy));
  };

  const getTotalOrders = (list) =>
    list.reduce((val, obj) => {
      return obj.testQty + val;
    }, 0);

  const filterDays = (val) => {
    if (!val) return "-";
    const arr = val.split(",");
    return arr;
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray
      .map((m) => ({
        ...m,
        status: m.message === "Eligibile" ? `1` : "0",
      }))
      .filter((obj) => {
        //goes through each key being filtered for
        return filterKeys.every((key) => {
          if (key === "createdAt") {
            return Object.keys(filters[key]).length > 0
              ? new Date(obj.createdAt) > filters[key].startDate?._d &&
                  new Date(obj.createdAt) < filters[key].endDate?._d
              : obj.createdAt;
          }
          if (key === "siteID") {
            return obj[key] && obj[key] === filters[key];
          }
          if (key === "subAgentID") {
            return obj[key] && obj[key] === filters[key];
          }
          if (key === "clientID") {
            return obj[key] && obj[key] === filters[key];
          }
          if (key === "month") {
            return moment(obj.createdAt).isSame(new Date(), "month");
          }
          if (!filters[key].length && !Object.keys(filters[key]).length) {
            return true;
          }
          if (key === "status") {
            return obj[key] === filters[key];
          }

          return obj[key] && obj[key].toLowerCase().includes(filters[key]);
        });
      });
    return models;
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    appContext.permission[CHECKELIGIBILITY]?.write && TABLE_QUICK_TOOLS.notes,
  ];

  useEffect(() => {
    if (pageNo > 1) {
      dispatch(fetchEligibityList(pageNo, filter));
    }
  }, [pageNo]);

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredEligibilities.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      dispatch(setPageNo(pageNo + 1));
    }
  };

  const itemsToMap = useMemo(() => {
    const list = filteredEligibilities ? [...filteredEligibilities] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT) {
      setCurrentPage(1);
    }
    if (currentPage > ttlPages) {
      setCurrentPage(ttlPages);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredEligibilities, currentPage]);

  useEffect(() => {
    fetchFromApi(appContext, pageNo, filter, true);
    dispatch(setPageNo(1));
  }, [filter]);

  const fetchFromApi = useCallback(
    debounce((appContext, pageNo, filter, val) => {
      dispatch(fetchEligibityList(pageNo, filter, val));
    }, 1000),
    []
  );

  const flipSort = (by) => {
    const sortOrder = sortBy === by ? !sortDescending : true;

    setSortDescending(sortOrder);
    setSortBy(by);
    filterOrderBy(by, sortOrder);
  };

  const filterOrderBy = (order, dir) => {
    const obj = {
      orderBy: order,
      orderByDirection: dir ? "desc" : "asc",
    };
    setFilter({ ...filter, ...obj });
  };

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false };
    setNewItem(obj);
    setOpenCreator(true);
  };

  const handleConfirmDelete = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await api.deleteOrder(itemToDelete.id);
      if (isDeleted) {
        // await getOrders();
        dispatch(fetchEligibityList(pageNo, filter, true));
        appContext.showSuccessMessage("Order deleted successfully");
      }
      setItemToDelete(null);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      [
        {
          firstName: ["First"],
          middleName: ["middle"],
          lastName: ["last"],
          gender: "",
          dob: ["09/06/1945"],
          address: ["1249 Abc Town"],
          address2: "",
          city: ["City"],
          state: ["Maryland"],
          zip: ["20657"],
          phone: "",
          agentPin: ["20"],
          medicareType: ["Medicare Part B"],
          medicareAgent: ["Medicare"],
          payerId: ["1192"],
          medicareNo: ["6230df8dj26"],
          email: "",
          MBINumber: "",
          automaticMonthly: ["Yes"],
        },
      ],
      t("eligibilityCsvSampleName")
    );
  };

  const handleImport = async (importData, subAgentID) => {
    if (importData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    // if (!locationID) {
    //   appContext.showErrorMessage("Facility/Agent was not selected");
    //   return;
    // }
    const arr = [];
    const arrdata = [];
    const testList = [...eligibilities];
    const importKeysArr = Object.keys(importData[0].data);

    const filterHeader = ELIGIBILITY_HEADERS.every((c) => importKeysArr.includes(c));
    if (!filterHeader) {
      appContext.showErrorMessage("Headers not matched");
      setOpenImportModal(false);
      return;
    }
    const subAgent = await api.getSubAgentById(subAgentID || CONFIG.generalSubAgent);
    const client = await api.getLocationById(subAgent.locationID);
    const company = await appContext.getClientById(subAgent.clientID);

    setOpenImportModal(false);
    setCsvUploadLoading(true);
    const fileList = [];
    const ttlLength = importData.length;
    const minimumRecords = 100;
    setLoading(true);
    let innerIndex = 0;
    for (let index = innerIndex; index < ttlLength; index++) {
      const { data } = importData[index];
      const test = {
        ...data,
      };

      if (Object.values(data).every((c) => c.length == 0)) continue;
      if (test.dob && test.dob.includes("/")) {
        test.dob = moment(test.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
      } else {
        test.dob = moment(test.dob).format("MM/DD/YYYY");
      }
      test.automaticMonthly = (test.automaticMonthly.toLowerCase() || "No") === "yes";
      test.firstName = getValidDep(test.firstName || "");
      test.lastName = getValidDep(test.lastName || "");
      if (!test.firstName || test.firstName === " ") {
        test.validation = "missing information";
        fileList.push(test);
        continue;
      }

      if (!test.lastName || test.lastName === " ") {
        test.validation = "missing information";
        fileList.push(test);
        continue;
      }

      if (!test.dob) {
        test.validation = "missing information";
        fileList.push(test);
        continue;
      }

      if (!isValidDobField(test.dob, "MM/DD/YYYY")) {
        test.validation = "Dob is not Valid";
        fileList.push(test);
        continue;
      }

      if (!moment(test.dob, "MM/DD/YYYY").isBetween("01/01/1900", currentDate())) {
        test.validation = "Dob is not Valid";
        fileList.push(test);
        continue;
      }

      if (!test.medicareNo.trim()) {
        test.validation = "missing information";
        fileList.push(test);
        continue;
      }

      if (!isValidIDNumber(test.medicareNo)) {
        test.validation = "medicareNo invalid";
        fileList.push(test);
        continue;
      }

      if (!test.medicareAgent || !test.medicareAgent.trim()) {
        test.validation = "missing information";
        fileList.push(test);
        continue;
      }

      let findVal = null;
      if (test.payerId) {
        findVal = INSURANCE_PROVIDER.find((f) => f.payerid === test.payerId.padStart(5, 0));
      } else {
        findVal = INSURANCE_PROVIDER.find((f) =>
          f.label.toLowerCase().replace(/\s/g, "").includes(test.medicareAgent.toLowerCase().replace(/\s/g, ""))
        );
      }
      if (!findVal) {
        test.validation = "Medicare Agent inValid";
        newEmpIds.push(test);
        continue;
      } else {
        test.insuranceCompany = findVal.payerid;
        test.payerId = findVal.payerid;
      }

      test.validation = "Pass";
      testList.push(test);
      fileList.push(test);
    }

    if (fileList.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setLoading(false);
      return;
    }
    const filterFileList = fileList.filter((f) => f.validation === "Pass");
    const percentage = Math.round((filterFileList.length / fileList.length) * 100);

    if (percentage >= 80) {
      const key = userCompanyID.get() || "admin";
      const fileName = `${key}/${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_date.csv`;

      try {
        await api.addEligibilityCsv(fileList, fileName, CONFIG.eligibilityBucket);
        const obj = {
          fileType: "Eligibility",
          fileName,
          totalRecord: fileList.length,
          clientID: company?.id,
          locationID: client?.id,
          locationName: client?.name,
          clientName: company?.name,
          subAgentName: subAgent?.name,
          subAgentID: subAgent?.id,
        };
        const res = await api.newEligibiliyFiles(obj, appContext.user);
        appContext.showSuccessMessage(
          `Thank you for submitting  ${fileList.length} records for eligibility checking. Your request is being processed. You will be notified in couple of minutes.`
        );
        setLoading(false);
      } catch (error) {
        appContext.showErrorMessage(error.message);
        console.log("Error:-", error.message);
        setLoading(false);
      }
    } else {
      appContext.showErrorMessage("Your Records not Correct more than 20% please recheck");
      await downloadDataAsCSV(fileList, t("eligibilityStatusFile"));
      setLoading(false);
    }

    setOpenImportModal(false);
    setCsvUploadLoading(false);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "checkEligibility");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "firstName" },
    { itemKey: "lastName" },
    { itemKey: "phoneNumber" },
    { itemKey: "email" },
    { itemKey: "medicalNo" },
  ];

  const tdFormat = (item, row) => {
    if (item === "message")
      return row?.eventData?.includes("Passed") ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    if (item === "groupId") return row.groupId !== "undefined" ? convertToUpper(row.groupId) : "";
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "message") return row?.eventData?.includes("Passed") ? "Eligible" : "InEligibile";
    if (item === "groupId") return row.groupId !== "undefined" ? convertToUpper(row.groupId) : "";
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
    if (e.target.checked && user.scheduleID) {
      setAssignProgram((a) =>
        a.length > 0 ? (a[0].scheduleID === user.scheduleID ? [...a, user] : [...a]) : [...a, user]
      );
    } else {
      setAssignProgram(assignProgram.filter((f) => f.id !== user.id));
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setNoteModal(row);
        break;
      case "view":
        dispatch(openEligibilityDetailModalAsync({ item: row }));
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("checkEligibility")} ({formatNumber(totalRecord)})
                </Card.Title>
                <div className="buttonHeader">
                  {!appContext.user?.isUser() && (
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  {!appContext.isReadOnly() &&
                    (appContext.permission[CHECKELIGIBILITY]?.write || !CONFIG.isLabType) && (
                      <Icon
                        handleClick={() => dispatch(setOpenCheckEligibilityModal(true))}
                        title={t("Eligibility")}
                        label={t("Eligibility")}
                        iconType={"acknowledgedPositiveIcon"}
                      />
                    )}

                  {!appContext.isReadOnly() &&
                    (ADMIN_USERS.includes(appContext?.user?.phone_number) ||
                      appContext?.user?.phone_number === "+923234217605") && (
                      <Icon
                        handleClick={handleDownloadSample}
                        title={"Download Csv Sample"}
                        label={"Download"}
                        iconType={"downloadIcon"}
                      />
                    )}

                  {!appContext.isReadOnly() && ADMIN_USERS.includes(appContext?.user?.phone_number) && (
                    <Icon
                      handleClick={() => {
                        setOpenImportModal(true);
                      }}
                      title={t("bulkEligibility")}
                      label={t("bulkEligibility")}
                      iconType={"importIcon"}
                      disabled={
                        appContext.eligibilityFiles.length > 0 && appContext.eligibilityFiles[0].status !== "Done"
                      }
                    />
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isCheckEligibility={true}
                  />
                )}
                {csvUploadLoading && <div style={{ textAlign: "right" }}>Fetching data...</div>}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  totalRecord={totalRecord}
                  currentRecord={filteredEligibilities.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredEligibilities.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  tools={quickTools}
                  rows={itemsToMap}
                  flipSort={flipSort}
                  selectedRows={checkboxes}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"checkEligibility"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={80}
                  customColumnCellRenderer={renderTd}
                  handleCellClick={handleCellClick}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Order"
          message="Are you sure, you want to delete Order?"
          handleConfirm={handleConfirmDelete}
          closeBtn
        />
      )}
      {orderModal && (
        <OrderModal
          handleClose={(isProcess, limitExccedEmployees, order) => {
            setOrderModal(false);
            if (isProcess) {
              if (limitExccedEmployees && limitExccedEmployees.length > 0) {
                appContext.showErrorMessage(
                  `${limitExccedEmployees.length} order request not processed because of limit exceed`
                );
              } else {
                appContext.showSuccessMessage(
                  `You have successfully ordered ${order.quantity} COVID-19 Antigen tests.`
                );
              }
              // getOrders();
              dispatch(fetchEligibityList(pageNo, filter));
            }
          }}
          employeeIds={appContext.employeeRecord ? [appContext.employeeRecord.id] : []}
          appContext={appContext}
          testAvailable={appContext.employeeRecord ? appContext.employeeRecord.testAvailable : 2}
        />
      )}
      {openCheckEligibilityModal && (
        <CheckEligibilityModal
          handleClose={(obj) => {
            if (obj) {
              // setOrders((prev) => [obj, ...prev]);
              // setfilteredEligibilities((prev) => [obj, ...prev]);
              dispatch(fetchEligibityList(pageNo, filter));
              if (obj.message === "Success") {
                dispatch(setEligibilityDetailsData(obj));
                setShowEligibilityDetails(true);
              }
            }
            dispatch(setOpenCheckEligibilityModal(false));
          }}
        />
      )}

      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          user={appContext.user}
          show={openOrderDetail}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
        />
      )}

      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImport}
          appContext={appContext}
          title="Bulk Eligibility Check"
          noSelectDate={true}
          handleDownloadSample={handleDownloadSample}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {openErrModal && (
        <ImportErrorModal
          title={title}
          errData={errorData}
          successData={successData}
          handleClose={() => {
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
          }}
        />
      )}
      {noteModal && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              dispatch(updateNotes(obj));
              obj.note
                ? dispatch(setMessage("add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
            }
            setNoteModal(null);
          }}
          user={loginUser}
          data={noteModal}
        />
      )}
    </div>
  );
};

export default checkEligibility;
