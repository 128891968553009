import { useRef } from "react";
import API from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import ProviderSearchRecord from "components/ProviderSearchRecord";
import { DEFAULT_PROVIDERS, DEFAULT_PROVIDERS_ARR, PARSE_PROVIDER } from "constant";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setMessage } from "../../store/features/general/generalAction";
import { MESSAGE_MODES, MESSAGE_TYPE, NPI_TYPE_OPTIONS } from "../../constant";
import { setLoader } from "../../store/features/general/generalSlice";
import { createProvider, deleteProvider, updateProvider } from "store/features/providers/providersActions";
import { setOpenCreatorProviderModal as setOpenCreator } from "store/features/providers/providersSlice";
import { t } from "../../stringConstants";
import InputField from "components/InputField/InputField";
import { formatPhoneNumber } from "utils";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import Form from "react-bootstrap/Form";
import { locationsSelectors } from "../../store/features/locations/locationsSelectors";

const ProviderModal = (props) => {
  const { show, user, handleClose, setting, readOnly, cssClass, providers } = props;

  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [defaultSetting, setDefaultSetting] = useState(user.isNew ? {} : setting.defaultSetting);
  const [phoneError, setPhoneError] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [selectSearchData, setSelectSearchData] = useState(null);
  const childNode = useRef();

  const CLIENT_OPTIONS = useSelector(locationsSelectors).map((f) => ({ label: f.name, value: f.id }));

  useEffect(() => {
    setNewUser(user);
  }, [user]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const handleSave = async (user, defaultSetting) => {
    try {
      if (user.isNew) {
        dispatch(createProvider(user, defaultSetting));
        dispatch(setMessage(t("providerCreatedSuccessfully"), MESSAGE_MODES.success));
      } else {
        dispatch(updateProvider(user, defaultSetting));
        dispatch(setMessage(t("providerUpdatedSuccessfully"), MESSAGE_MODES.success));
      }
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      dispatch(setOpenCreator(true));
    }
  };

  const populate = (record) => {
    setSelectSearchData(null);
    setRecordData([]);

    const { basic, addresses, taxonomies } = record;

    // Find the LOCATION address if available
    const locationAddress = addresses?.find((address) => address.address_purpose === "LOCATION") || addresses[0];

    // Determine the primary taxonomy
    const primaryTaxonomy = taxonomies.find((taxonomy) => taxonomy.primary) || taxonomies[0];

    // Format phone numbers if they exist
    const formattedPhoneNumber = locationAddress?.telephone_number
      ? formatPhoneNumber(`+1${locationAddress.telephone_number}`)
      : "";

    const formattedFaxNumber = locationAddress?.fax_number ? formatPhoneNumber(`+1${locationAddress.fax_number}`) : "";

    // Create the user object
    const obj = {
      ...newUser,
      firstName: basic.first_name,
      middleName: basic.middle_name,
      lastName: basic.last_name,
      street: locationAddress?.address_1,
      city: locationAddress?.city,
      zip: locationAddress?.postal_code,
      state: locationAddress?.state,
      phoneNumber: formattedPhoneNumber,
      fax: formattedFaxNumber,
      taxid: primaryTaxonomy?.code,
      npi: record.number,
      npiType: record.enumeration_type,
      speciality: primaryTaxonomy?.desc,
      isFinder: true,
    };

    setNewUser(obj);
  };

  const searchProvider = async (provider) => {
    let record = [];
    dispatch(setLoader(true));
    try {
      record = await API.searchProviderAPI(provider);
      dispatch(setLoader(false));
    } catch (err) {
      console.log(err.message);
      dispatch(setLoader(false));
    }

    if (record.length === 0) {
      setError("records Not Found!");
      return;
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(record);
    }
  };

  const handleFindProvider = (newUSer) => {
    const keys = ["firstName", "lastName", "npi"];
    if (keys.some((e) => newUser[e])) {
      searchProvider(newUser);
    } else {
      dispatch(setMessage("Please Fill Provider Name Or NPI", MESSAGE_MODES.info));
    }
  };

  const onHandleSave = () => {
    let roles = [];
    const objToSave = { ...newUser };
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setError("First Name is required");
      return;
    }

    Object.assign(objToSave, {
      name: `${newUser.firstName.trim()} ${newUser?.middleName?.trim() || ""} ${newUser?.lastName.trim()}`,
    });

    if (!newUser.npi || !newUser.npi.trim()) {
      setError("NPI is required");
      return;
    }

    // Find Existing NPI Number
    const findProvider = providers.find((f) => f.npi === newUser.npi);

    if (findProvider && (newUser.isNew || (!newUser.isNew && newUser.id !== user.id))) {
      setError("NPI is already exist");
      return;
    }

    let provDefaultSetting = defaultSetting;
    if (!newUser.isNew && setting.defaultSetting && Object.keys(setting.defaultSetting).length > 0) {
      const obj = {};
      DEFAULT_PROVIDERS_ARR.forEach((key) => {
        if (defaultSetting[key] === setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] !== setting.defaultSetting[key] && setting.defaultSetting[key] === newUser.id) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] && defaultSetting[key] !== setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else {
          obj[key] = setting.defaultSetting[key] || "";
        }
      });
      provDefaultSetting = obj;
    }

    handleSave(objToSave, provDefaultSetting);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        className={cssClass ?? ""}
        size={"lg"}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create Provider" : "Update Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className={"d-flex w-100 align-items-center justify-content-around"}>
            <InputField
              type="text"
              groupWrapper={"form-group-wrapper w-35"}
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="NPI:"
              value={newUser.npi}
              index="npi"
              maxLength={20}
              placeholder="Search By NPI"
              handleChange={(e) => setNewUser({ ...newUser, npi: e.target.value })}
              required
            />

            <InputField
              type="text"
              groupWrapper={"form-group-wrapper w-35"}
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="Code:"
              value={newUser.code}
              index="code"
              maxLength={8}
              placeholder="Code"
              handleChange={(e) => setNewUser({ ...newUser, code: e.target.value })}
              required
            />

            <Button
              style={{ marginBottom: 20 }}
              variant="secondary"
              className="modalButtons  align-middle headerButton btn-fill"
              onClick={() => handleFindProvider(newUser)}
            >
              Search
            </Button>
          </div>
          <div className="row">
            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="First Name"
                  value={newUser.firstName}
                  index="firstName"
                  maxLength={30}
                  placeholder="First Name"
                  handleChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Middle Name"
                  value={newUser.middleName}
                  index="middleName"
                  maxLength={30}
                  placeholder="Middle Name"
                  handleChange={(e) => setNewUser({ ...newUser, middleName: e.target.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Last Name"
                  value={newUser.lastName}
                  index="lastName"
                  maxLength={30}
                  placeholder="Last Name"
                  handleChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                />
              </div>
            </div>
            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="address"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="Address"
                  value={newUser.street}
                  index="street"
                  maxLength={65}
                  placeholder="Address"
                  handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput w-100"
                  labelStyle="text-capitalize"
                  label="City"
                  value={newUser.city}
                  index="city"
                  maxLength={20}
                  placeholder="City"
                  handleChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                />
                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="State"
                  value={newUser.state}
                  index="state"
                  maxLength={20}
                  placeholder="State"
                  handleChange={(e) => setNewUser({ ...newUser, state: e.target.value })}
                />
              </div>
            </div>

            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Zip Code"
                  value={newUser.zip}
                  index="zip"
                  maxLength={20}
                  placeholder="Zip Code"
                  handleChange={(e) => setNewUser({ ...newUser, zip: e.target.value })}
                />

                <InputField
                  type="phone"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Phone Number"
                  value={newUser.phoneNumber}
                  index="phoneNumber"
                  maxLength={20}
                  placeholder="Enter Phone"
                  handleChange={(e) => setNewUser({ ...newUser, phoneNumber: e })}
                />

                <InputField
                  type="phone"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Fax"
                  value={newUser.fax}
                  index="fax"
                  maxLength={20}
                  placeholder="Enter Fax"
                  handleChange={(e) => setNewUser({ ...newUser, fax: e })}
                />
              </div>
            </div>

            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-row4 crew-profile-again"}>
                <InputField
                  type="dropDown"
                  options={NPI_TYPE_OPTIONS}
                  inputStyle=""
                  labelStyle="text-capitalize"
                  label="NPI Type:"
                  isClearable={true}
                  value={NPI_TYPE_OPTIONS.find((f) => f.value === newUser.npiType) || ""}
                  index="npiType"
                  placeholder="NPI"
                  handleChange={(e) => setNewUser({ ...newUser, npiType: e?.value })}
                />

                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  label="Taxonomy ID:"
                  value={newUser.taxid}
                  index="taxid"
                  maxLength={20}
                  placeholder="Taxonomy ID"
                  handleChange={(e) => setNewUser({ ...newUser, taxid: e.target.value })}
                />
                <InputField
                  type="text"
                  label="Speciality:"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  value={newUser.speciality || ""}
                  placeholder="Enter Speciality"
                  index="specialitypi"
                  handleChange={(e) => setNewUser({ ...newUser, speciality: e.target.value })}
                />
              </div>
            </div>

            <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
              <div className={" crew-profile-column2 crew-profile-again"}>
                {/* <Form.Group className="form-group-wrapper">
                  <Form.Label>Default:</Form.Label>

                  <Select
                    options={DEFAULT_PROVIDERS}
                    blurInputOnSelect={true}
                    isClearable={true}
                    isMulti
                    menuPlacement="auto"
                    value={
                      defaultSetting && Object.keys(defaultSetting).length > 0
                        ? Object.entries(defaultSetting)
                            .filter(([key, value]) => value === newUser.id || (newUser.isNew && value))
                            .map(([key, val]) => ({ value: key, label: PARSE_PROVIDER[key] }))
                        : null
                    }
                    placeholder="Select Defualt Provider"
                    onChange={(e) => {
                      let obj = {};
                      e.map((m) => {
                        obj[m.value] = newUser.id || true;
                      });

                      setDefaultSetting(obj);
                    }}
                  />
                </Form.Group> */}
                <Form.Group className="form-group-wrapper">
                  <Form.Label>Associated Client:</Form.Label>

                  <Select
                    options={CLIENT_OPTIONS}
                    blurInputOnSelect={true}
                    isClearable={true}
                    menuPlacement="auto"
                    value={CLIENT_OPTIONS.find((f) => f.value === newUser.associatedClient) || ""}
                    placeholder="Select Client"
                    onChange={(e) => setNewUser({ ...newUser, associatedClient: e?.value })}
                  />
                </Form.Group>
              </div>
            </div>

            <div
              className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
            >
              <span className="section-title">Active: </span>
              <SwitchToggle
                checked={newUser.isActive}
                handleChange={(e) =>
                  setNewUser({
                    ...newUser,
                    isActive: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          {!readOnly && (!newUser.isNew || newUser.isFinder) && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              {newUser.isNew ? "Create" : "Update"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {recordData.length > 1 && (
        <ProviderSearchRecord
          data={recordData}
          handleSelect={populate}
          handleClose={() => {
            setRecordData([]), setSelectSearchData(null);
          }}
          setSelectSearchData={setSelectSearchData}
          selectSearchdata={selectSearchData}
        />
      )}
    </>
  );
};

export default ProviderModal;
