import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const TabPills = (props) => {
  const { tabs, defaultInd } = props;

  return (
    <>
      <Tabs defaultIndex={defaultInd}>
        <TabList>
          {tabs.map((item, i) => (
            <Tab key={i}>
              {item.title} {item.length ? <b>({item.length})</b> : ""}
            </Tab>
          ))}
        </TabList>

        {tabs.map((item, i) => (
          <TabPanel key={i}>{item.component}</TabPanel>
        ))}
      </Tabs>
    </>
  );
};
export default TabPills;
